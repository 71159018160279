/* eslint-disable */
import superfish from 'superfish/src/js/superfish';
import Instafeed from 'instafeed.js/instafeed.min';
import treeview from 'treemenu.js/treemenu';
import { equalHeight } from 'equalHeight/equalheight';
import fancybox from '@fancyapps/fancybox/dist/jquery.fancybox.min';
$.fancybox.defaults.hash = false;

export default function () {
  let instaload = true;

  //hide the result if user click out side of that box, also add view all button and redirect user to search page by clicking on it,
  document.addEventListener('click', function (e) {
    if (e.currentTarget.activeElement.id != 'quickSearch') {
      document.querySelector('.quickSearchResults').innerHTML = '';
    }
  });

  function setSearchBox() {
    if ($(window).width() < 1024 && !$('body').hasClass('lt-1024')) {
      $('body').removeClass('gt-1024');
      $('body').addClass('lt-1024');
      $('#quickSearch').detach().appendTo('.MobileSearch');

      $('.header').removeClass('stuck');
      $('.body').removeClass('body-stuck');

      $('.side-bar-toggle-title .sidebarBlock-heading').each(function () {
        if (!$(this).next().is(':hidden')) {
          $(this).addClass('Active');
        }
      });
      $('.side-bar-toggle-title .sidebarBlock-heading').next().hide();
      $('.side-bar-toggle-title .sidebarBlock-heading').removeClass('Active');

    } else if ($(window).width() > 1023 && !$('body').hasClass('gt-1024')) {
      $('body').removeClass('lt-1024');
      $('body').addClass('gt-1024');
      $('#quickSearch').detach().prependTo('.header-mid-section .navUser');
      $('.side-bar-toggle-title .sidebarBlock-heading').removeClass('Active');
      $('.side-bar-toggle-title .sidebarBlock-heading').next().show();
    } else {
    }
  }



  function treeviewfunc() {
    $(".navList-treeview").not(".navList-treeview.treeview").treeview({
      collapsed: true,
      animated: "medium",
      collapsedArrow: '<svg><use xlink:href="#icon-angle-down"></use></svg>',
      expandedArrow: '<svg><use xlink:href="#icon-angle-up"></use></svg>'
    });
  }

  function giveHeight() {

    equalHeight('.productCarousel .card-body');
    equalHeight('.brandGrid .card-body');

  }

  function superfish_mobile() {
    if ($(window).width() <= 767) {
      $('.header-navigation .mob-userSummary').superfish({
        delay: 8,
        speed: "fast"
      });
    } else {
      $('.header-navigation .mob-userSummary').superfish('destroy');
    }
  }


  $(document).ready(function () {

    // Active class in menu
    var path = window.location.pathname;
    $("#menu li a").each(function () {
      if (path == $(this)[0].pathname || path == "/" + $(this)[0].pathname) {
        $(this).parents('li').addClass("active-category");
        $(this).parent().addClass("active-category");
      }
    });


    treeviewfunc();
    setSearchBox();

    setTimeout(() => {
      giveHeight();
    }, 1000);


    // close top pencil banner in mobile
    $(document).on('click', '.control-close-btn', function () {
      $('.pencil-banner').slideUp();
    });

    /* hide dropdown when hover over usermenu */
    $(document).on('mouseenter', '.usermenu a', function () {
      if ($('[data-cart-preview]').hasClass('is-open')) {
        $('[data-cart-preview]').click();
      }
      if ($('.quickSearchResults li').length) {
        $('.quickSearchResults').html('');
      }
    });

    $('[data-cart-preview]').click(function () {
      if ($('.quickSearchResults li').length) {
        $('.quickSearchResults').html('');
      }
    });

    /* ============ SUPERFISH SCRIPT ============ */

    $('.navPages .navPages-list, .header-navigation .nav-currency, .userSummary').superfish({
      delay: 8,
      speed: "fast"
    });

    superfish_mobile();


    /* Scroll to review section */
    $('.productView-reviewLink a').not('.productView-reviewLink.write-review a').click(function (e) {
      e.preventDefault();
      var headerHeight = 0;

      if ($(window).width() > 1023) {
        headerHeight = $('#menu').height() + 25;
      }

      if ($('.productView.productView--quickView').length) {
        if ($('#ProductTabsQuickview .r-tabs-nav').is(':visible') && !$('#ProductTabsQuickview .r-tabs-nav a[href="#Quickviewtab-reviews"]').parent().hasClass('r-tabs-state-active')) {
          $('#ProductTabsQuickview .r-tabs-nav a[href="#Quickviewtab-reviews"]').trigger('click');
        } else if (!$('#ProductTabsQuickview .r-tabs-accordion-title a[href="#Quickviewtab-reviews"]').parent().hasClass('r-tabs-state-active')) {
          $('#ProductTabsQuickview .r-tabs-accordion-title a[href="#Quickviewtab-reviews"]').trigger('click');
        } else {

        }

        $('html, body').animate({
          'scrollTop': $("#Quickviewtab-reviews").offset().top - $('#ProductTabsQuickview a[href="#Quickviewtab-reviews"]').outerHeight() - headerHeight
        }, 1000);
      } else {
        if ($('.ProductInfoTab .r-tabs-nav').is(':visible') && !$('.ProductInfoTab .r-tabs-nav a[href="#tab-review"]').parent().hasClass('r-tabs-state-active')) {
          $('.ProductInfoTab .r-tabs-nav a[href="#tab-review"]').trigger('click');
        } else if (!$('.ProductInfoTab .r-tabs-accordion-title a[href="#tab-review"]').parent().hasClass('r-tabs-state-active')) {
          $('.ProductInfoTab .r-tabs-accordion-title a[href="#tab-review"]').trigger('click');
        } else {

        }

        $('html, body').animate({
          'scrollTop': $("#tab-review").offset().top - $('.ProductInfoTab a[href="#tab-review"]').outerHeight() - headerHeight
        }, 1000);

      }


    });

    setTimeout(function () {
      let wURL = location.href;
      if (wURL.indexOf('#product-reviews') > -1) {
        $('.productView-reviewLink a').not('.productView-reviewLink.write-review a').trigger('click');
      }
    }, 100);



    /* product image with fancybox */

    //Don't enable Cloud Zoom (product image zoom) on touch device
    //Mouseenter/Mouseover events are not ideal for touch devices
    //for more info search for this code in /script/main.js
    if (('ontouchstart' in window) || (navigator.msMaxTouchPoints > 0)) {

    }


    $('ul.productView-thumbnails li a').mouseenter(function () {
      $(this).removeAttr('data-fancybox');
      $(this).attr("href", "javascript:void(0)");
    });
    $('ul.productView-thumbnails li a').mouseleave(function () {
      $(this).attr('data-fancybox', 'gallery');
      $(this).attr("href", $(this).attr('data-fancybox-href'));
    });


    if (document.querySelectorAll('.productView-image.main-product-page a,.main-product-page .easyzoom-flyout').length) {
      document.querySelector('.productView-image.main-product-page a,.main-product-page .easyzoom-flyout').addEventListener('touchstart', function (e) {
        e.preventDefault()
        $('ul.productView-thumbnails li a.is-active').attr('data-fancybox', 'gallery');
        $('ul.productView-thumbnails li a.is-active').attr('href', $('ul.productView-thumbnails li a.is-active').attr('data-fancybox-href'));
        $('ul.productView-thumbnails li a.is-active').trigger('click');
      });
    }



    $(document).on('click', '.productView-image.main-product-page a', function (e) {
      e.preventDefault();
      $('ul.productView-thumbnails li a.is-active').attr('data-fancybox', 'gallery');
      $('ul.productView-thumbnails li a.is-active').attr('href', $('ul.productView-thumbnails li a.is-active').attr('data-fancybox-href'));
      $('.productView-thumbnails li').each(function () {
        $(this).find("a").attr('href', $(this).find("a").attr('data-fancybox-href'));
        $(this).find("a").attr('data-fancybox', 'gallery');
      });
      $('ul.productView-thumbnails li a.is-active').trigger('click');
    });


    $(document).on('click', '.main-product-page .easyzoom-flyout', function (e) {
      $('ul.productView-thumbnails li a.is-active').attr('data-fancybox', 'gallery');
      $('ul.productView-thumbnails li a.is-active').attr('href', $('ul.productView-thumbnails li a.is-active').attr('data-fancybox-href'));
      $('.productView-thumbnails li').each(function () {
        $(this).find("a").attr('href', $(this).find("a").attr('data-fancybox-href'));
        $(this).find("a").attr('data-fancybox', 'gallery');
      });
      $('ul.productView-thumbnails li a.is-active').trigger('click');

    });

    /*  Fancybox  */



    /* MOBILE MENU SCRIPT */

    $(".mobile-menu-btn").click(function () {
      $("body").addClass("mobile-menu-open");
    });

    $(".mobile-menu-close-btn a").click(function () {
      $("body").removeClass("mobile-menu-open");
    });

    $(".menu-open-overlay").click(function () {
      $("body").removeClass("mobile-menu-open");
    });

    /* Footer Expand Collapse Js Starts */

    $('.footer .footer-info-heading').click(function () {
      if ($(window).width() < 768) {
        $('.footer .footer-info-heading').removeClass('active').next('.footer-info-list').slideUp();
        if ($(this).next('.footer-info-list').is(':hidden')) {
          $(this).addClass('active');
          $(this).next('.footer-info-list').slideDown();
        } else {
          $(this).removeClass('active');
          $(this).next('.footer-info-list').slideUp();
        }
      }
    });


    /* ==== MOBILE Togglemenu ==== */

    $(document).on('click', '.side-bar-toggle-title .sidebarBlock-heading', function () {
      if ($(window).width() < 1024) {
        $(this).toggleClass('Active');
        $(this).next().slideToggle();
      }
    });


    /* ============== sticky menu =============== */

    let headerHeight = $('header').height();
    let scrollElement = false;
    let hHeader = $('.header').innerHeight();
    let hInner = hHeader - 10;
    $(window).scroll(function () {
      if ($('[sticky="true"]').length && $(window).width() > 1023) {
        if (hHeader < $(window).scrollTop() && !scrollElement) {
          if ($('.header').length) {
            $('.header').addClass('stuck');
            $('.body').addClass('body-stuck').css('margin-top', headerHeight + 'px');
          }
          scrollElement = true;
        } else if (hInner > $(window).scrollTop() && scrollElement) {
          $('.header').removeClass('stuck');
          $('.body').removeClass('body-stuck').css('margin-top', '0px');
          scrollElement = false;
        }
      }
    });


    /* ============ INSTAGRAM SCRIPT START ============ */

    function InstaFeedSlick() {
      $('#instafeed').slick({
        dots: false,
        //infinite: false,
        centerMode: false,
        centerPadding: '100px',
        slidesToShow: 9,
        arrows: true,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 4000,
        nextArrow: '<button class="slick-next slick-arrow" aria-label="Next" type="button"><svg><use xlink:href="#icon-angle-right"></use></svg></button>',
        prevArrow: '<button class="slick-prev slick-arrow" aria-label="Previous" type="button"><svg><use xlink:href="#icon-angle-left"></use></svg></button>',
        responsive: [
          {
            breakpoint: 1901,
            settings: {
              slidesToShow: 9,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 1651,
            settings: {
              slidesToShow: 9,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 1441,
            settings: {
              slidesToShow: 9,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 1025,
            settings: {
              slidesToShow: 7,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 1023,
            settings: {
              slidesToShow: 6,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 769,
            settings: {
              slidesToShow: 6,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 668,
            settings: {
              slidesToShow: 5,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 569,
            settings: {
              slidesToShow: 4,
              centerPadding: '80px',
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 481,
            settings: {
              slidesToShow: 3,
              centerPadding: '70px',
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 321,
            settings: {
              slidesToShow: 2,
              centerPadding: '70px',
              slidesToScroll: 1
            }
          }
        ]
      });
    }


    let loadingImg = $('[data-loading]').data('loading');
    var countLimit = 20;
    let feed = new Instafeed({
      get: 'user',
      limit: countLimit,
      userId: $("#instafeed").attr("data-id"),
      accessToken: $("#instafeed").attr("data-key"),
      resolution: "low_resolution",
      template: '<div class="insta-item-wrap {{orientation}}"><div class="insta-item"><a class="animation" href="{{link}}" target="_blank"><img class="lazyload" src="' + loadingImg + '" data-src="{{image}}" /></a></div></div>',
      after: function () {
        InstaFeedSlick();
      },
      success: function (data) {
        sessionStorage.setItem('instadata', JSON.stringify(data.data));
      }
    });

    $(window).scroll(function () {
      if (($(window).scrollTop() > $(window).height() / 2) && ($.trim($('#instafeed').html()) == "") && instaload) {
        instaload = false;
        if ($("#instafeed").length && $("#instafeed").attr("data-id") != "" && $("#instafeed").attr("data-key") != "" && sessionStorage.getItem('instadata') == null) {
          feed.run();
        } else {

          if ($("#instafeed").length) {
            const instaData = JSON.parse(sessionStorage.getItem('instadata'));
            const getInstaImaes = instaData.map(item => item.images.low_resolution.url);
            const getInstaData = instaData.map(item => item.link);
            let totallength = '';

            if (countLimit < instaData.length) {
              totallength = countLimit;
            } else {
              totallength = instaData.length;
            }

            for (let i = 0; i < totallength; i++) {
              $('#instafeed').append('<div class="insta-item-wrap {{orientation}}"><div class="insta-item"><a class="animation" href="' + getInstaData[i] + '" target="_blank"><img class="lazyload" src="' + loadingImg + '" data-src="' + getInstaImaes[i] + '" /></a></div></div>')
            }

            InstaFeedSlick();

          }


        }

      }
    });
    /* ============ INSTAGRAM SCRIPT END ============ */


  });

  // ready end ============

  $(window).on("resize", function () {

    if ($(window).width() < 768 && !$('body').hasClass('lt-768')) {
      $('body').addClass('lt-768');
      $('body').removeClass('gt-768');
      $('.footer .footer-info-list').hide();
    } else if ($(window).width() > 767 && !$('body').hasClass('gt-768')) {
      $('body').addClass('gt-768');
      $('body').removeClass('lt-768');
      $('.footer .footer-info-heading').removeClass('active');
      $('.footer .footer-info-list').show();
    }

    setSearchBox();
    //product_view();
    setTimeout(() => {
      giveHeight();
      superfish_mobile();
    }, 1000);

  });

  $(document).ajaxComplete(function () {

    treeviewfunc();
    // product_view();
    setTimeout(() => {
      giveHeight();
    }, 1000);

  });



}


/* eslint-enable */
