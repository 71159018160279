/*eslint-disable*/
import $ from 'jquery';
import _ from 'lodash';
import utils from '@bigcommerce/stencil-utils';
import StencilDropDown from './stencil-dropdown';

export default function(){
var c=0;
const TOP_STYLING = 'top: 49px;'
const $action=$('#search_advance');
const $quickSearchResults = $('.quickSearchResults');
const $quickSearchDiv = $('#quickSearch');
const $searchQuery = $('#search_query');
const $filter_val="";
const stencilDropDownExtendables = {
    hide: () => {
        $searchQuery.blur();
    },
    show: (event) => {
        $searchQuery.focus();
        event.stopPropagation();
    },
};
const stencilDropDown = new StencilDropDown(stencilDropDownExtendables);
stencilDropDown.bind($('[data-search="quickSearch"]'), $quickSearchDiv, TOP_STYLING);

stencilDropDownExtendables.onBodyClick = (e, $container) => {
    // If the target element has this data tag or one of it's parents, do not close the search results
    // We have to specify `.modal-background` because of limitations around Foundation Reveal not allowing
    // any modification to the background element.
    if ($(e.target).closest('[data-prevent-quick-search-close], .modal-background').length === 0) {
        stencilDropDown.hide($container);
    }
};

const doSubmit=_.throttle((submitQuery) => {
  if(submitQuery=='')
  {
    alert('Please write any keyword to Search.');
  }else{
    window.location.href=submitQuery;
  }
}, 100);

const makeSearch=_.debounce((searchQuery) => {
  if(searchQuery.length)
  {
    utils.api.getPage(searchQuery, { template: 'search/quick-results' }, (err, content) => {
      $quickSearchResults.html(content);
    });
  }
},100);

$(document).on('change','.search_new',(e)=>{
     $('input[category-cont]').attr('category-cont',$(e.currentTarget).val());
});

utils.hooks.on('search-quick',(event) => {
  const search_query_val=$(event.currentTarget).val();
  const filter_val=$(event.currentTarget).attr('category-cont');
  var query=$action.attr('action');
  var filter="";
  var search="";
  try{
      switch (filter_val) {
         case '':
            search=search_query_val;
         break;

         default:
           search=search_query_val;
           filter+=`&category=${filter_val}`;
         break;
      }

      if(search_query_val.length < 3){
        return;
      }
      query = `${query}?mode=1&price_from=&price_to=&featured=&shipping=&search_query_adv=${search}${filter}&section=product`;
      console.log(query);
      $searchQuery.attr('do-search',`${query}`);
      makeSearch(query);
      c++;
  }
  catch(err){
    return modal.updateContent(err);
  }
});

$quickSearchDiv.on('submit', (event) => {
  const searchQuery = $searchQuery.attr('do-search');
  doSubmit(searchQuery);
  return event.preventDefault();
});

}
/*eslint-enable*/
